import { createContext, useContext, useMemo, useState } from 'react';
import { IViewCustomerPrices, NoPropsJustChildren } from 'shared/types';

type ModalStatus =
    | {
          selectionType: 'drawer' | 'exceptions';
          selectedCustomerPrice: IViewCustomerPrices;
      }
    | {
          selectionType: 'closed' | 'quote';
          selectedCustomerPrice: undefined;
      };

type SelectedDevCustomerPriceContext = {
    status: ModalStatus;
    close: () => void;
    openDrawer: (selectedCustomerPrice: IViewCustomerPrices) => void;
    isDrawerOpen: boolean;
    openExceptionsModal: (selectedCustomerPrice: IViewCustomerPrices) => void;
    isExceptionsModalOpen: boolean;
    openQuoteModal: () => void;
    isQuoteModalOpen: boolean;
};

const ALL_MODALS_CLOSED: ModalStatus = { selectionType: 'closed', selectedCustomerPrice: undefined };

export const selectedDevCustomerPriceContext = createContext<SelectedDevCustomerPriceContext>({
    status: ALL_MODALS_CLOSED,
    close: () => {},
    openDrawer: (selectedCustomerPrice: IViewCustomerPrices) => {},
    isDrawerOpen: false,
    openExceptionsModal: (selectedCustomerPrice: IViewCustomerPrices) => {},
    isExceptionsModalOpen: false,
    openQuoteModal: () => {},
    isQuoteModalOpen: false,
});

export function ProvideSelectedDevCustomerPrice({ children }: NoPropsJustChildren) {
    const [status, setStatus] = useState<ModalStatus>(ALL_MODALS_CLOSED);

    const context: SelectedDevCustomerPriceContext = useMemo(
        () => ({
            status,
            close: () => setStatus(ALL_MODALS_CLOSED),
            openDrawer: (selectedCustomerPrice: IViewCustomerPrices) =>
                setStatus({ selectedCustomerPrice, selectionType: 'drawer' }),
            isDrawerOpen: status?.selectionType === 'drawer',
            openExceptionsModal: (selectedCustomerPrice: IViewCustomerPrices) =>
                setStatus({ selectedCustomerPrice, selectionType: 'exceptions' }),
            isExceptionsModalOpen: status?.selectionType === 'exceptions',
            openQuoteModal: () => setStatus({ selectionType: 'quote', selectedCustomerPrice: undefined }),
            isQuoteModalOpen: status?.selectionType === 'quote',
        }),
        [status]
    );

    return (
        <selectedDevCustomerPriceContext.Provider value={context}>{children}</selectedDevCustomerPriceContext.Provider>
    );
}

export function useSelectedDevCustomerPrice() {
    return useContext(selectedDevCustomerPriceContext);
}

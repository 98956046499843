import { DEFAULT_ROUTES } from '@insight2profit/drive-app';

export const ROUTES = {
    ...DEFAULT_ROUTES,
    productPrices: '/product-prices',
    productPricesAddRecord: '/product-prices/add-record',
    customerPrices: '/customer-prices',
    devCustomerPrices: '/dev-customer-prices',
    customerPricesAddNewRecord: '/customer-prices/add-new',
    customerPricesAddExistingSAPRecord: '/customer-prices/add-existing-sap',
    moduleRedirect: '/module-redirect',
    analytics: '/analytics',
} as const;

import { ImmutableObject } from '@hookstate/core';
import { CLIENT_ID } from '@insight2profit/drive-app';
import { ServerSideState } from '@price-for-profit/data-grid';
import { DataAccessPaginatedResponse, DataAccessResponse, NoInfer, getData } from '@price-for-profit/micro-services';
import { QueryClient } from '@tanstack/react-query';
import {
    DATABASE_LABEL,
    MASS_ACTION_TYPES,
    SP_MASS_ACTION_CUSTOMER_PRICES,
    TABLE_CUSTOMER_PRICES,
    VIEW_TEMPORAL_CUSTOMER_PRICES,
    customerPriceStatuses,
} from 'shared/constants';
import {
    ICustomerPricesApprovalMassActionParameters,
    ICustomerPricesMassActionParameters,
    ICustomerPricesMassActionValidation,
    ICustomerPricesSubmitMassActionParameters,
    ITableCustomerPrices,
    IViewCustomerPrices,
} from 'shared/types';
import { ICustomerPriceApprovalService } from './customerPriceApprovalService';
import { IExchangeRatesService } from './exchangeRatesService';
import { IRowLevelSecurityDasService, PermittedRowLevels } from './rowLevelSecurityDasService';
import { IUomConversionService } from './uomConversionService';

type GetGridDataParams = {
    state: ServerSideState;
    customerPricesPermittedRowLevels: PermittedRowLevels;
};

type SoftEditGridRowDataParams = {
    newViewRow: IViewCustomerPrices;
    oldViewRow: IViewCustomerPrices;
    userDisplayName?: string;
    userEmail: string;
    isForeignCurrency?: boolean;
    queryClient?: QueryClient;
};

type PriceChangeMassActionParams = {
    state: ServerSideState;
    customerPricesPermittedRowLevels: PermittedRowLevels;
    parameters: ICustomerPricesMassActionParameters;
    userApproverTier: number;
};

type ApprovalMassActionParams = {
    state: ServerSideState;
    customerPricesPermittedRowLevels: PermittedRowLevels;
    parameters: ICustomerPricesApprovalMassActionParameters;
    userApproverTier: number;
};

type SubmitMassActionParams = {
    state: ServerSideState;
    customerPricesPermittedRowLevels: PermittedRowLevels;
    userApproverTier: number;
    modifiedBy?: string;
    submittedByEmail?: string;
};

type ValidatePriceChangeMassActionParams = {
    state: ServerSideState;
    customerPricesPermittedRowLevels: PermittedRowLevels;
    parameters: ICustomerPricesMassActionParameters;
};

type StatusModifierParams =
    | {
          action: 'approve' | 'reject';
          payload: {
              userDisplayName: string;
              now: string;
              currentStatus: string;
          };
      }
    | {
          action: 'edit';
          payload: {
              userDisplayName: string;
              userEmail: string;
              now: string;
              currentStatus: string;
          };
      }
    | {
          action: 'submit';
          payload: {
              userDisplayName: string;
              userEmail: string;
              now: string;
              currentStatus: string;
              oldViewRow: IViewCustomerPrices;
              userApproverTier: number;
          };
      };

type StatusModifierResponse = Partial<ITableCustomerPrices>;

export interface IDevCustomerPricesService {
    getGridData({
        state,
        customerPricesPermittedRowLevels,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewCustomerPrices>>;
    getAllGridData({ state, customerPricesPermittedRowLevels }: GetGridDataParams): Promise<IViewCustomerPrices[]>;
    softEditGridRowData({
        isForeignCurrency,
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
        queryClient,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }>;
    massAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
        userApproverTier,
    }: PriceChangeMassActionParams): Promise<void>;
    validateMassAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
    }: ValidatePriceChangeMassActionParams): Promise<DataAccessPaginatedResponse<ICustomerPricesMassActionValidation>>;
    approvalMassAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
        userApproverTier,
    }: ApprovalMassActionParams): Promise<void>;
    submitMassAction({
        state,
        customerPricesPermittedRowLevels,
        userApproverTier,
        modifiedBy,
        submittedByEmail,
    }: SubmitMassActionParams): Promise<void>;
    submitRow(
        oldViewRow: IViewCustomerPrices,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }>;
    approveRow(
        oldViewRow: IViewCustomerPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }>;
    rejectRow(
        oldViewRow: IViewCustomerPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }>;
    getConvertUomToUom(
        oldUom?: string,
        newUom?: string,
        materialId?: string,
        queryClient?: QueryClient
    ): Promise<(uomMoney?: number) => number | undefined>;
    isSubmitAllowed(oldViewRow: IViewCustomerPrices): boolean;
}

export class DevCustomerPricesService implements IDevCustomerPricesService {
    constructor(
        private rlsDasService: IRowLevelSecurityDasService,
        private customerPriceApprovalService: ICustomerPriceApprovalService,
        private uomConversionService: IUomConversionService,
        private exchangeRatesService: IExchangeRatesService
    ) {}

    async getGridData({
        state,
        customerPricesPermittedRowLevels,
    }: GetGridDataParams): Promise<DataAccessPaginatedResponse<IViewCustomerPrices>> {
        return await this.rlsDasService.getCollectionWithRls<IViewCustomerPrices, typeof DATABASE_LABEL>(
            state,
            customerPricesPermittedRowLevels,
            {
                clientId: CLIENT_ID,
                databaseLabel: DATABASE_LABEL,
                tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
                page: state.pageNumber,
                pageSize: state.pageSize,
                sortBy: state.sortModel[0]?.field as NoInfer<keyof IViewCustomerPrices>,
                sortDescending: state.sortModel[0]?.sort === 'desc',
            }
        );
    }
    async getAllGridData({
        state,
        customerPricesPermittedRowLevels,
    }: GetGridDataParams): Promise<IViewCustomerPrices[]> {
        return await getData((page: number) =>
            this.rlsDasService.getCollectionWithRls<IViewCustomerPrices, typeof DATABASE_LABEL>(
                state,
                customerPricesPermittedRowLevels,
                {
                    clientId: CLIENT_ID,
                    databaseLabel: DATABASE_LABEL,
                    tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
                    page,
                    pageSize: state.pageSize,
                    sortBy: state.sortModel[0]?.field as NoInfer<keyof IViewCustomerPrices>,
                    sortDescending: state.sortModel[0]?.sort === 'desc',
                }
            )
        );
    }

    async softEditGridRowData({
        newViewRow,
        oldViewRow,
        userDisplayName,
        userEmail,
        isForeignCurrency,
        queryClient,
    }: SoftEditGridRowDataParams): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow = await this.createNewRow({
            isForeignCurrency,
            newViewRow,
            oldTableRow,
            userDisplayName: userDisplayName || '',
            now,
            statusModifierParams: {
                action: 'edit',
                payload: {
                    userDisplayName: userDisplayName || '',
                    userEmail,
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
            queryClient,
        });

        await this.softEditValidation({ newTableRow: modifiedNewTableRow, oldTableRow: oldTableRow });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        return {
            editResponse,
            addResponse,
            newViewRow,
        };
    }

    async massAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
        userApproverTier,
    }: PriceChangeMassActionParams) {
        await this.rlsDasService.executeMassActionWithRls<
            IViewCustomerPrices,
            ICustomerPricesMassActionParameters & { UserApproverTier: number }
        >(state, customerPricesPermittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
            queryName: SP_MASS_ACTION_CUSTOMER_PRICES,
            parameters: { ...parameters, UserApproverTier: userApproverTier },
            page: state.pageNumber,
            pageSize: state.pageSize,
        });
    }

    async validateMassAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
    }: ValidatePriceChangeMassActionParams): Promise<DataAccessPaginatedResponse<ICustomerPricesMassActionValidation>> {
        return await this.rlsDasService.executeMassActionWithRls<
            IViewCustomerPrices,
            ICustomerPricesMassActionParameters,
            ICustomerPricesMassActionValidation
        >(state, customerPricesPermittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
            queryName: 'spMassActionCustomerPricesValidation',
            parameters,
            page: 0,
            pageSize: 1000,
        });
    }

    async approvalMassAction({
        state,
        customerPricesPermittedRowLevels,
        parameters,
        userApproverTier,
    }: ApprovalMassActionParams) {
        await this.rlsDasService.executeMassActionWithRls<
            IViewCustomerPrices,
            ICustomerPricesApprovalMassActionParameters
        >(state, customerPricesPermittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
            queryName: 'spMassActionCustomerPricesApproval',
            parameters: { ...parameters, UserApproverTier: userApproverTier },
            page: state.pageNumber,
            pageSize: state.pageSize,
        });
    }

    async submitMassAction({
        state,
        customerPricesPermittedRowLevels,
        userApproverTier,
        modifiedBy,
        submittedByEmail,
    }: SubmitMassActionParams) {
        if (!modifiedBy) throw new Error('User not found.');

        await this.rlsDasService.executeMassActionWithRls<
            IViewCustomerPrices,
            ICustomerPricesSubmitMassActionParameters
        >(state, customerPricesPermittedRowLevels, {
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: VIEW_TEMPORAL_CUSTOMER_PRICES,
            queryName: 'spMassActionCustomerPricesSubmit',
            parameters: {
                Type: MASS_ACTION_TYPES.SUBMIT,
                UserApproverTier: userApproverTier,
                ModifiedBy: modifiedBy,
                SubmittedByEmail: submittedByEmail,
            },
            page: state.pageNumber,
            pageSize: state.pageSize,
        });
    }

    isSubmitAllowed(oldViewRow: IViewCustomerPrices): boolean {
        if (oldViewRow.isScalePrice) {
            if ((oldViewRow.scaleQty01 && !oldViewRow.amount01) || (oldViewRow.amount01 && !oldViewRow.scaleQty01)) {
                return false;
            }
            if ((oldViewRow.scaleQty02 && !oldViewRow.amount02) || (oldViewRow.amount02 && !oldViewRow.scaleQty02)) {
                return false;
            }
            if ((oldViewRow.scaleQty03 && !oldViewRow.amount03) || (oldViewRow.amount03 && !oldViewRow.scaleQty03)) {
                return false;
            }
            if ((oldViewRow.scaleQty04 && !oldViewRow.amount04) || (oldViewRow.amount04 && !oldViewRow.scaleQty04)) {
                return false;
            }
            if ((oldViewRow.scaleQty05 && !oldViewRow.amount05) || (oldViewRow.amount05 && !oldViewRow.scaleQty05)) {
                return false;
            }
            if ((oldViewRow.scaleQty06 && !oldViewRow.amount06) || (oldViewRow.amount06 && !oldViewRow.scaleQty06)) {
                return false;
            }
        }
        if (!oldViewRow.revisedPrice && !oldViewRow.isScalePrice) {
            return false;
        }

        return (
            oldViewRow.status === customerPriceStatuses.NEEDS_REVIEW ||
            oldViewRow.status === customerPriceStatuses.NO_CHANGE
        );
    }

    async submitRow(
        updatedViewRow: IViewCustomerPrices,
        userApproverTier: number,
        user: ImmutableObject<app.UserInfo>
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }> {
        if (!this.isSubmitAllowed(updatedViewRow)) throw new Error('Submit not allowed');

        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPrices = await this.getOldTableRow(updatedViewRow.massActionId);

        const modifiedNewTableRow = await this.createNewRow({
            newViewRow: updatedViewRow,
            oldTableRow,
            userDisplayName: user?.displayName || '',
            now,
            statusModifierParams: {
                action: 'submit',
                payload: {
                    userDisplayName: user?.displayName || '',
                    userEmail: user?.email || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                    userApproverTier,
                    oldViewRow: updatedViewRow,
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...updatedViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async approveRow(
        oldViewRow: IViewCustomerPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableCustomerPrices = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            now,
            userDisplayName,
            statusModifierParams: {
                action: 'approve',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    async rejectRow(
        oldViewRow: IViewCustomerPrices,
        userDisplayName: string
    ): Promise<{
        editResponse: DataAccessResponse<ITableCustomerPrices>;
        addResponse: DataAccessResponse<ITableCustomerPrices>;
        newViewRow: IViewCustomerPrices;
    }> {
        const now = new Date().toISOString();

        const oldTableRow: ITableCustomerPrices = await this.getOldTableRow(oldViewRow.massActionId);

        const modifiedNewTableRow: ITableCustomerPrices = await this.createNewRow({
            newViewRow: oldViewRow,
            oldTableRow,
            now,
            userDisplayName,
            statusModifierParams: {
                action: 'reject',
                payload: {
                    userDisplayName: userDisplayName || '',
                    now,
                    currentStatus: oldTableRow.status || '',
                },
            },
        });

        const editResponse = await this.termOldTableRow(oldTableRow, now);

        const addResponse = await this.addNewRow(modifiedNewTableRow);

        const newViewRow = {
            ...oldViewRow,
            ...modifiedNewTableRow,
        };

        return { editResponse, addResponse, newViewRow };
    }

    private async createNewRow({
        newViewRow,
        oldTableRow,
        userDisplayName,
        now,
        statusModifierParams,
        isForeignCurrency,
        queryClient,
    }: {
        newViewRow: IViewCustomerPrices;
        oldTableRow: ITableCustomerPrices;
        userDisplayName: string;
        now: string;
        statusModifierParams: StatusModifierParams;
        isForeignCurrency?: boolean;
        queryClient?: QueryClient;
    }) {
        if (!newViewRow.validFrom || !newViewRow.validTo) {
            throw new Error('Missing valid from or valid to date');
        }

        const validFromNoTimeUnits = new Date(newViewRow.validFrom).toLocaleDateString('en-US');
        const validToNoTimeUnits = new Date(newViewRow.validTo).toLocaleDateString('en-US');
        const isUomChange = oldTableRow.uom !== newViewRow.uom;

        const editableFields = {
            validFrom: validFromNoTimeUnits,
            validTo: validToNoTimeUnits,
            isScalePrice: newViewRow.isScalePrice,
            revisedPrice: newViewRow.isScalePrice ? oldTableRow.revisedPrice : newViewRow.revisedPrice,
            scaleQty01: newViewRow.isScalePrice ? newViewRow.scaleQty01 : oldTableRow.scaleQty01,
            amount01: newViewRow.isScalePrice ? newViewRow.amount01 : oldTableRow.amount01,
            scaleQty02: newViewRow.isScalePrice ? newViewRow.scaleQty02 : oldTableRow.scaleQty02,
            amount02: newViewRow.isScalePrice ? newViewRow.amount02 : oldTableRow.amount02,
            scaleQty03: newViewRow.isScalePrice ? newViewRow.scaleQty03 : oldTableRow.scaleQty03,
            amount03: newViewRow.isScalePrice ? newViewRow.amount03 : oldTableRow.amount03,
            scaleQty04: newViewRow.isScalePrice ? newViewRow.scaleQty04 : oldTableRow.scaleQty04,
            amount04: newViewRow.isScalePrice ? newViewRow.amount04 : oldTableRow.amount04,
            scaleQty05: newViewRow.isScalePrice ? newViewRow.scaleQty05 : oldTableRow.scaleQty05,
            amount05: newViewRow.isScalePrice ? newViewRow.amount05 : oldTableRow.amount05,
            scaleQty06: newViewRow.isScalePrice ? newViewRow.scaleQty06 : oldTableRow.scaleQty06,
            amount06: newViewRow.isScalePrice ? newViewRow.amount06 : oldTableRow.amount06,
        };

        const newTableRow: ITableCustomerPrices = {
            ...oldTableRow,
            ...(isUomChange ? await this.uomChangeFields(newViewRow, oldTableRow, queryClient) : editableFields),
            perQuantity: newViewRow.perQuantity,
            perQuantityInSAP: newViewRow.perQuantityInSAP,
        };

        const statusModifier = this.statusModifier(statusModifierParams);

        if (isForeignCurrency) {
            const currentDocumentCurrencyCode = newViewRow.documentCurrency;

            if (!currentDocumentCurrencyCode || currentDocumentCurrencyCode === '') {
                throw new Error(`Document Currency is empty`);
            }
            const exchangeRatesResponse = await this.exchangeRatesService.getExchangeRates();

            const matchingExchangeRate = exchangeRatesResponse?.data?.find(anExchangeRate => {
                return anExchangeRate.fromCurrencyCode === currentDocumentCurrencyCode;
            });
            if (!matchingExchangeRate) {
                throw new Error(`A matching exchange rate was not found for ${currentDocumentCurrencyCode}`);
            }

            const modifiedNewTableRow: ITableCustomerPrices = {
                ...newTableRow,
                ...statusModifier,
                massActionId: 0,
                modifiedBy: userDisplayName,
                effectiveStart: now,
                effectiveEnd: undefined,
                deleted: false,
            };

            return modifiedNewTableRow;
        }
        const modifiedNewTableRow: ITableCustomerPrices = {
            ...newTableRow,
            ...statusModifier,
            massActionId: 0,
            modifiedBy: userDisplayName,
            effectiveStart: now,
            effectiveEnd: undefined,
            deleted: false,
        };
        return modifiedNewTableRow;
    }

    private async uomChangeFields(
        newViewRow: IViewCustomerPrices,
        oldTableRow: ITableCustomerPrices,
        queryClient?: QueryClient
    ) {
        const convertUomToUom = await this.uomConversionService.getConverterForStandardAndNonStandardUom(
            oldTableRow.uom,
            newViewRow.uom,
            newViewRow.materialId,
            queryClient
        );

        const hasPriceTypeAttribute = !!newViewRow.priceTypeAttributes;

        const editableFields = {
            revisedPrice:
                oldTableRow.revisedPrice === newViewRow.revisedPrice && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.revisedPrice)
                    : hasPriceTypeAttribute
                    ? newViewRow.currentPrice || newViewRow.revisedPrice
                    : newViewRow.revisedPrice,
            amount01:
                oldTableRow.amount01 === newViewRow.amount01 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount01)
                    : newViewRow.amount01,
            amount02:
                oldTableRow.amount02 === newViewRow.amount02 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount02)
                    : newViewRow.amount02,
            amount03:
                oldTableRow.amount03 === newViewRow.amount03 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount03)
                    : newViewRow.amount03,
            amount04:
                oldTableRow.amount04 === newViewRow.amount04 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount04)
                    : newViewRow.amount04,
            amount05:
                oldTableRow.amount05 === newViewRow.amount05 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount05)
                    : newViewRow.amount05,
            amount06:
                oldTableRow.amount06 === newViewRow.amount06 && !hasPriceTypeAttribute
                    ? convertUomToUom(newViewRow.amount06)
                    : newViewRow.amount06,
        };

        const result: Partial<ITableCustomerPrices> = {
            ...editableFields,
            currentCost: convertUomToUom(newViewRow.currentCost),
            regionalMinimum: convertUomToUom(newViewRow.regionalMinimum),
            regionalTarget: convertUomToUom(newViewRow.regionalTarget),
            globalMinimum: convertUomToUom(newViewRow.globalMinimum),
            globalTarget: convertUomToUom(newViewRow.globalTarget),
            recommendedPrice: convertUomToUom(newViewRow.recommendedPrice),
            isScalePrice: newViewRow.isScalePrice,
            scaleQty01: newViewRow.scaleQty01,
            scaleQty02: newViewRow.scaleQty02,
            scaleQty03: newViewRow.scaleQty03,
            scaleQty04: newViewRow.scaleQty04,
            scaleQty05: newViewRow.scaleQty05,
            scaleQty06: newViewRow.scaleQty06,
            ttmQuantity: newViewRow.ttmQuantity,
            ttmRevenue: newViewRow.ttmRevenue,
            forecastedCost3Month: convertUomToUom(newViewRow.forecastedCost3Month),
            forecastedCost6Month: convertUomToUom(newViewRow.forecastedCost6Month),
            forecastedCost9Month: convertUomToUom(newViewRow.forecastedCost9Month),
            currentFloorPrice: convertUomToUom(newViewRow.currentFloorPrice),
            updatedFloorPrice: convertUomToUom(newViewRow.updatedFloorPrice),
            updatedCost: convertUomToUom(newViewRow.updatedCost),
            uom: newViewRow.uom,
        };
        return result;
    }

    async getConvertUomToUom(oldUom?: string, newUom?: string, materialId?: string, queryClient?: QueryClient) {
        return await this.uomConversionService.getConverterForStandardAndNonStandardUom(
            oldUom,
            newUom,
            materialId,
            queryClient
        );
    }

    private async addNewRow(newTableRow: ITableCustomerPrices) {
        return await this.rlsDasService.addRow<ITableCustomerPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES,
            payload: newTableRow,
        });
    }

    private async termOldTableRow(
        oldTableRow: ITableCustomerPrices,
        now: string
    ): Promise<DataAccessResponse<ITableCustomerPrices>> {
        return await this.rlsDasService.updateRow<ITableCustomerPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES,
            payload: {
                ...oldTableRow,
                effectiveEnd: now,
            },
        });
    }

    private async getOldTableRow(massActionId: number): Promise<ITableCustomerPrices> {
        const { data: oldTableRow } = await this.rlsDasService.getSingle<ITableCustomerPrices, typeof DATABASE_LABEL>({
            clientId: CLIENT_ID,
            databaseLabel: DATABASE_LABEL,
            tableId: TABLE_CUSTOMER_PRICES,
            key: massActionId.toString(),
        });

        if (!oldTableRow || !!oldTableRow.effectiveEnd || !!oldTableRow.deleted) {
            throw new Error('Data not valid. Please refresh the page and try again.');
        }

        return oldTableRow;
    }

    private async softEditValidation({
        newTableRow,
    }: {
        newTableRow: ITableCustomerPrices;
        oldTableRow: ITableCustomerPrices;
    }) {
        if (!newTableRow.modifiedBy) throw new Error('User display name not found');

        if (!newTableRow.isScalePrice && newTableRow.revisedPrice && newTableRow.revisedPrice <= 0) {
            throw new Error('Revised Price must be greater than 0');
        }

        if (newTableRow.isScalePrice) {
            const errorMessage = this.validateScalePricingPairs(newTableRow);
            if (errorMessage) throw new Error(errorMessage);
        }
    }

    private statusModifier({ action, payload }: StatusModifierParams): StatusModifierResponse {
        switch (action) {
            case 'edit':
                return {
                    status: customerPriceStatuses.NEEDS_REVIEW,
                    approver: undefined,
                    editedBy: payload.userDisplayName,
                    editedDate: payload.now,
                    editedByEmail: payload.userEmail,
                    approvedBy: undefined,
                    approvedDate: undefined,
                    submittedBy: undefined,
                    submittedByEmail: undefined,
                    submittedByDate: undefined,
                };
            case 'submit':
                if (!payload.oldViewRow.isScalePrice) {
                    if (payload.oldViewRow.revisedPrice === null || payload.oldViewRow.revisedPrice === undefined) {
                        throw new Error('Cannot submit a non scale pricing row without revised price');
                    }
                }
                if (
                    payload.currentStatus === customerPriceStatuses.NEEDS_REVIEW ||
                    payload.currentStatus === customerPriceStatuses.NO_CHANGE
                ) {
                    const customerPricesApprovalColumns = this.customerPriceApprovalService.determinePriceForComparison(
                        payload.oldViewRow
                    );

                    const { isAutoApproved, approver } = this.customerPriceApprovalService.getSubmitStatus({
                        row: customerPricesApprovalColumns,
                        userApproverTier: payload.userApproverTier,
                    });

                    if (isAutoApproved) {
                        return {
                            status: customerPriceStatuses.APPROVED,
                            approver,
                            approvedBy: payload.userDisplayName,
                            approvedDate: payload.now,
                            submittedBy: payload.userDisplayName,
                            submittedByEmail: payload.userEmail,
                            submittedByDate: payload.now,
                        };
                    }

                    return {
                        status: customerPriceStatuses.APPROVAL_REQUIRED,
                        approver,
                        approvedBy: undefined,
                        approvedDate: undefined,
                        submittedBy: payload.userDisplayName,
                        submittedByEmail: payload.userEmail,
                        submittedByDate: payload.now,
                    };
                }
                throw Error('Invalid submit');
            case 'approve':
                return {
                    status: customerPriceStatuses.APPROVED,
                    approvedBy: payload.userDisplayName,
                    approvedDate: payload.now,
                };
            case 'reject':
                return {
                    status: customerPriceStatuses.REJECTED,
                    approvedBy: undefined,
                    approvedDate: undefined,
                };
            default:
                throw Error('Invalid status change');
        }
    }

    private validateScalePricingPairs = (newViewRow: ITableCustomerPrices) => {
        const scalePricingTuples = [
            [newViewRow.scaleQty01, newViewRow.amount01],
            [newViewRow.scaleQty02, newViewRow.amount02],
            [newViewRow.scaleQty03, newViewRow.amount03],
            [newViewRow.scaleQty04, newViewRow.amount04],
            [newViewRow.scaleQty05, newViewRow.amount05],
            [newViewRow.scaleQty06, newViewRow.amount06],
        ];

        const result = scalePricingTuples.reduce(
            (tracker, tuple, index) => {
                const [scale, amt] = tuple;
                const correctedIndex = index + 1;

                if (scale === undefined || scale === null) {
                    tracker.invalidationReasons.push(`Scale ${correctedIndex} is required`);
                }
                if (amt === undefined || amt === null) {
                    tracker.invalidationReasons.push(`Amt ${correctedIndex} is required`);
                }
                if (!scale && !!amt) {
                    tracker.invalidationReasons.push(
                        `Scale ${correctedIndex} must have > 0 value, since Amt ${correctedIndex} is > 0`
                    );
                }
                if (!!scale && !amt) {
                    tracker.invalidationReasons.push(
                        `Amt ${correctedIndex} must be > 0, since Scale ${correctedIndex} is > 0`
                    );
                }
                if (scale === undefined || scale === null || scale < 0) {
                    tracker.invalidationReasons.push(`Scale ${correctedIndex} must be >= 0`);
                }
                if (amt === undefined || amt === null || amt < 0) {
                    tracker.invalidationReasons.push(`Amt ${correctedIndex} must be >= 0`);
                }
                if (tracker.isPreviousScaleZero && Math.round(scale || 0) !== 0) {
                    tracker.invalidationReasons.push(`Scale ${correctedIndex} must be 0 if a previous scale is 0`);
                }
                if (tracker.isPreviousAmtZero && amt !== 0) {
                    tracker.invalidationReasons.push(`Amt ${correctedIndex} must be 0 if a previous amt is 0`);
                }
                if (scale !== undefined && Math.round(scale) <= tracker.highestScale && scale !== 0) {
                    tracker.invalidationReasons.push(`Scale ${correctedIndex} must be > all previous scales or 0`);
                }
                if (amt !== undefined && amt >= tracker.lowestAmt && amt !== 0) {
                    tracker.invalidationReasons.push(`Amt ${correctedIndex} must be < all previous amts or 0`);
                }

                return {
                    highestScale: Math.max(tracker.highestScale, Math.round(scale || 0)),
                    lowestAmt: Math.min(tracker.lowestAmt, amt || 0),
                    isPreviousScaleZero: scale === 0 || tracker.isPreviousScaleZero,
                    isPreviousAmtZero: amt === 0 || tracker.isPreviousAmtZero,
                    invalidationReasons: tracker.invalidationReasons,
                };
            },
            {
                highestScale: 0,
                lowestAmt: Number.MAX_VALUE,
                isPreviousScaleZero: false,
                isPreviousAmtZero: false,
                invalidationReasons: [] as string[],
            }
        );
        if (result.invalidationReasons.length > 0) {
            return `${result.invalidationReasons[0]}`;
        }
        return null;
    };
}

import { GridFilterItem, GridFilterModel, GridInitialState, GridLinkOperator } from '@mui/x-data-grid-premium';
import { ServerSideState } from '@price-for-profit/data-grid';

interface ICreateCustomerPricesInitialStateProps {
    filterModelItems: GridFilterItem[];
}

interface ICreateCustomerPricesInitialStateReturn {
    initialState?: Partial<ServerSideState>;
    dataGridInitialState?: Omit<GridInitialState, 'pagination' | 'sorting' | 'filter'>;
    rowsPerPageOptions?: number[];
}

export function createCustomerPricesInitialState({
    filterModelItems,
}: ICreateCustomerPricesInitialStateProps): ICreateCustomerPricesInitialStateReturn {
    const filterModel: GridFilterModel = {
        items: filterModelItems,
    };
    const initialState: Partial<ServerSideState> = {
        pageSize: 10,
        sortModel: [{ field: 'material', sort: 'desc' }],
        filterModel: { ...filterModel, linkOperator: GridLinkOperator.Or },
    };

    const dataGridInitialState = {
        pinnedColumns: { left: ['__check__', 'shipTo', 'material'] },
        columns: {
            columnVisibilityModel: {
                parentId: false,
                newRecordId: false,
                orgRegion: false,
                application: false,
                accountAssignmentGroup: false,
                marketSegment: false,
                applicationSubMarket: false,
                applicationGroup: false,
                salesChannel: false,
                materialType: false,
                salesOrganization: false,
                division: false,
                distributionChannel: false,
                productReachCategory: false,
                elasticityCategory: false,
                agFoodCompositeIndex: false,
                pmi: false,
                productPeer: false,
                purchasingBreadthCategory: false,
            },
        },
    };

    const rowsPerPageOptions = [10, 20, 50, 100];

    return {
        initialState,
        dataGridInitialState,
        rowsPerPageOptions,
    };
}

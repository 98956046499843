import { Stack } from '@mui/material';

import { ProvideAnalyticsPrerequisites, ProvideAvailableAnalyticsReports } from 'packages/individualAnalytics';

import { useIsDemoMode, useTitle, useUser } from '@insight2profit/drive-app';
import { PageLayout } from 'shared/components/layouts';
import Loading from 'shared/components/routing/loading';
import { useAnalyticsAppInfo } from 'shared/hooks/use-analytics-app-info';
import { ProvideSelectedDevCustomerPrice, useExchangeRates } from 'shared/providers';
import { DevCustomerPrices } from './customerPrices';

export const pageName = 'Customer Prices';

export function DevCustomerPricesResolver() {
    const { prerequisites, options } = useAnalyticsAppInfo();
    const isDemoMode = useIsDemoMode();
    useTitle(`${pageName} - ${isDemoMode ? 'PMT' : 'Nouryon'}`);
    const user = useUser();

    const {
        exchangeRates: { isLoading },
    } = useExchangeRates();

    if (isLoading || !user) {
        return <Loading />;
    }

    return (
        <PageLayout pageName={pageName}>
            <Stack gap={2}>
                <ProvideAnalyticsPrerequisites prerequisites={prerequisites} options={options}>
                    <ProvideAvailableAnalyticsReports>
                        {/* <CustomerPricesSummaryResolver> */}
                        <ProvideSelectedDevCustomerPrice>
                            <DevCustomerPrices user={user} />
                        </ProvideSelectedDevCustomerPrice>
                        {/* </CustomerPricesSummaryResolver> */}
                    </ProvideAvailableAnalyticsReports>
                </ProvideAnalyticsPrerequisites>
            </Stack>
        </PageLayout>
    );
}

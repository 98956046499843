import { useService, useUser } from '@insight2profit/drive-app';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField, SaveButton } from 'shared/components/forms';
import { customerPriceStatuses } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import {
    useCustomerPriceCommentsMutation,
    useCustomerPricesExceptionsApproveMutation,
    useCustomerPricesExceptionsRejectMutation,
} from 'shared/mutations';
import { useCustomerPricesExceptionsApprovalEmailMutation } from 'shared/mutations/customerPricesExceptionsApprovalEmailMutation';
import { usePricesInvalidator } from 'shared/providers';
import { ISpGetCustomerPricesExceptions, ITableCustomerPricesCommentsForm, IViewCustomerPrices } from 'shared/types';
import { TooltipIconButton } from '../../productPrices';

type ProductPricesApprovalActionsProps = {
    params: GridRowParams<ISpGetCustomerPricesExceptions>;
    isInEditMode?: boolean;
    customerPricesRow?: IViewCustomerPrices;
};

const APPROVE = 'Approve';
const REJECT = 'Reject';

type DialogType = typeof APPROVE | typeof REJECT | null;

export function CustomerPricesExceptionsApprovalActions({
    params,
    isInEditMode,
    customerPricesRow,
}: ProductPricesApprovalActionsProps) {
    const [dialogType, setDialogType] = useState<DialogType>(null);
    const { userApproverTier } = useUserPermissions();
    const { customerPriceApprovalService } = useService();
    const { invalidateQuery } = usePricesInvalidator();
    const user = useUser();

    const {
        handleSubmit,
        control,
        formState: { errors },
        resetField,
        reset,
    } = useForm<ITableCustomerPricesCommentsForm>({
        defaultValues: {
            comment: '',
        },
    });

    const rejectMutation = useCustomerPricesExceptionsRejectMutation();
    const approveMutation = useCustomerPricesExceptionsApproveMutation();
    const { isLoading: isCommentMutationLoading } = useCustomerPriceCommentsMutation();
    const { isLoading: isApprovalEmailMutationLoading } = useCustomerPricesExceptionsApprovalEmailMutation();

    const openApproveDialog = () => {
        setDialogType(APPROVE);
    };

    const openRejectDialog = () => {
        setDialogType(REJECT);
    };

    const closeDialog = () => {
        reset();
        setDialogType(null);
    };

    const isOpen = !!dialogType;

    const onSubmit = async (form: ITableCustomerPricesCommentsForm) => {
        if (!form.comment) throw Error('Comment cannot be blank');
        if (!customerPricesRow) throw Error('Error retrieving customer price information');
        if (!user) throw Error('Error retrieving user information');
        if (!params.row) throw Error('Error retrieving exception price information');

        if (dialogType === APPROVE) {
            await approveMutation.mutateAsync({ row: params.row, comment: form.comment, customerPricesRow });
        }

        if (dialogType === REJECT) {
            await rejectMutation.mutateAsync({ row: params.row, comment: form.comment, customerPricesRow });
        }

        resetField('comment');
        invalidateQuery();
        closeDialog();
    };

    const canApprove = customerPriceApprovalService.canCurrentUserApprove({
        row: params.row,
        userApproverTier,
    });

    const requiresApproval = params.row.status === customerPriceStatuses.APPROVAL_REQUIRED;

    const isDisabled = !requiresApproval || !canApprove || !!errors?.['comment'];
    const isLoading =
        rejectMutation.isLoading ||
        approveMutation.isLoading ||
        isCommentMutationLoading ||
        isApprovalEmailMutationLoading;

    return !isInEditMode
        ? [
              <TooltipIconButton
                  key={`approve-tooltip-btn-${params.row.id}`}
                  tooltip='Approve'
                  onClick={openApproveDialog}
                  Icon={DoneIcon}
                  color='green'
                  isDisabled={isDisabled}
              />,
              <TooltipIconButton
                  key={`reject-tooltip-btn-${params.row.id}`}
                  tooltip='Reject'
                  onClick={openRejectDialog}
                  Icon={CloseIcon}
                  color='red'
                  isDisabled={isDisabled}
              />,
              <Dialog
                  key={`approval-dialog-${params.row.id}`}
                  onClose={closeDialog}
                  open={isOpen}
                  maxWidth={'sm'}
                  fullWidth
              >
                  <DialogTitle>{dialogType}</DialogTitle>
                  <DialogContent>
                      <Box sx={{ p: 2 }}>
                          <FormTextField
                              name={'comment'}
                              label={'Comment'}
                              control={control}
                              rules={{ maxLength: 1000, required: 'cannot be blank' }}
                              fieldError={errors['comment']}
                          />
                      </Box>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={closeDialog}>Cancel</Button>
                      <SaveButton
                          text={dialogType || ''}
                          submitHandler={handleSubmit(onSubmit)}
                          isLoading={isLoading}
                          isDisabled={!!errors['comment']}
                      />
                  </DialogActions>
              </Dialog>,
          ]
        : [];
}

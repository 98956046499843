import { useService, useUser } from '@insight2profit/drive-app';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPremium, GridRowId, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit, useI2pSimpleDataGrid } from '@price-for-profit/data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { QUERY_KEYS } from 'shared/constants';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { ProvidePricesInvalidator, useExchangeRates } from 'shared/providers';
import { CustomerPriceUniqueIdentifiers, ISpGetCustomerPricesExceptions, IViewCustomerPrices } from 'shared/types';
import { CustomerPricesExceptionsCustomToolbar } from './CustomerPricesExceptionCustomToolbar';
import { useCustomerPricesExceptionsColumns } from './CustomerPricesExceptionsResolver';

type CustomerPricesExceptionsProps = {
    customerPricesRow: IViewCustomerPrices;
};

export function CustomerPricesExceptions({ customerPricesRow }: CustomerPricesExceptionsProps) {
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const { customerPricesExceptionsService } = useService();
    const { enqueueSnackbar } = useSnackbar();
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const user = useUser();
    const { isForeignCurrency } = useExchangeRates();
    const queryClient = useQueryClient();

    const [newRows, setNewRows] = useState<readonly any[]>([]);

    const customerPriceUniqueIdentifiers: CustomerPriceUniqueIdentifiers = {
        businessLine: customerPricesRow.businessLine,
        materialId: customerPricesRow.materialId,
        shipToId: customerPricesRow.shipToId,
        soldToId: customerPricesRow.soldToId,
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = newRows.find(row => row.id === id);
        if (editedRow!?.isNew) {
            setNewRows(newRows.filter(row => row.id !== id));
        }
    };

    const columns = useCustomerPricesExceptionsColumns(rowModesModel, handleCancelClick, customerPricesRow);

    const { getDataGridProps, invalidate } = useI2pSimpleDataGrid<ISpGetCustomerPricesExceptions>({
        columns,
        name: QUERY_KEYS.customerPricesExceptions,
        additionalKeys: [customerPriceUniqueIdentifiers],
        getData: async () => {
            return (await customerPricesExceptionsService.getGridData({ customerPriceUniqueIdentifiers })).data;
        },
        queryOptions: {
            onError: _error => {
                enqueueSnackbar(`Unable to get Exceptions for customer price record`, { variant: 'error' });
            },
        },
        dataGridInitialState: {
            columns: {
                // this is to force datagrid pro to store initial columnVisibilityModel as part of
                // apiRef.current.state so it can be reverted to in the default view from the toolbar
                columnVisibilityModel: {
                    xxx: false,
                },
            },
            pagination: {
                pageSize: 10,
            },
            sorting: {
                sortModel: [{ field: 'effectiveStart', sort: 'desc' }],
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });

    const { getDataGridEditProps } = useI2pDataGridEdit<ISpGetCustomerPricesExceptions>({
        onEdit: async (newViewRow, oldViewRow) => {
            if (!newViewRow.salesOrder) {
                enqueueSnackbar('Sales Order is required', { variant: 'error' });
                setNewRows(rows => rows.filter(row => row.id !== newViewRow.id));
                return oldViewRow;
            }

            if (!/^\d+$/.test(newViewRow.salesOrder)) {
                enqueueSnackbar('Sales Order must contain only numbers', { variant: 'error' });
                setNewRows(rows => rows.filter(row => row.id !== newViewRow.id));
                return oldViewRow;
            }

            let result: ISpGetCustomerPricesExceptions;

            if (newViewRow.isNew) {
                await customerPricesExceptionsService.softAddGridRowData({
                    newViewRow: {
                        ...newViewRow,
                        id: '',
                        editedBy: user?.displayName,
                        editedByEmail: user?.email || '',
                    },
                });

                result = { ...newViewRow, isNew: false };
            } else {
                if (JSON.stringify(oldViewRow) === JSON.stringify(newViewRow)) {
                    enqueueSnackbar('No edit made', { variant: 'warning' });
                    return oldViewRow;
                }

                newViewRow.validTo = newViewRow.validTo ? new Date(newViewRow.validTo).toUTCString() : '';
                newViewRow.validFrom = newViewRow.validFrom ? new Date(newViewRow.validFrom).toUTCString() : '';

                const customerPricesExceptionsResult = await customerPricesExceptionsService.softEditGridRowData({
                    isForeignCurrency,
                    newViewRow,
                    oldViewRow,
                    userDisplayName: user?.displayName,
                    userEmail: user?.email || '',
                    queryClient,
                });
                result = customerPricesExceptionsResult.newViewRow;
            }

            invalidate();
            queryClient.invalidateQueries([QUERY_KEYS.customerPrices]);
            enqueueSnackbar('Edit saved', { variant: 'success' });
            setNewRows(rows => rows.map(row => (row.id === newViewRow.id ? result : row)));
            setErrorModalMessage('');
            return result;
        },
        onError: (error: Error) => {
            invalidate();
            enqueueSnackbar(`Edit failed: ${error.message}`, { variant: 'error' });
            setErrorModalMessage(error.message);
        },
    });

    const dataGridProps = getDataGridProps();
    const dataGridEditProps = getDataGridEditProps();

    useEffect(() => {
        setNewRows([]);
    }, [dataGridProps.rows]);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const regionalCurrencyNotEditable = ['documentCurrency', 'uom'];

    const rows = [...dataGridProps.rows, ...newRows];

    return (
        <Box height='calc(100vh - 300px)'>
            <ProvidePricesInvalidator invalidateQuery={invalidate}>
                <DataGridPremium
                    {...dataGridProps}
                    {...dataGridEditProps}
                    columns={columns}
                    rows={rows}
                    sx={DATA_GRID_STYLE}
                    disableSelectionOnClick
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    components={{
                        Toolbar: CustomerPricesExceptionsCustomToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-filterFormColumnInput': {
                                    width: 'auto',
                                },
                            },
                        },
                        toolbar: { setRows: setNewRows, setRowModesModel, customerPricesRow },
                    }}
                    editMode='row'
                    disableVirtualization={false}
                    headerHeight={HEADER_HEIGHT}
                    getRowId={row => row.id}
                    isCellEditable={params => {
                        if (params.row.status === 'Approved') return false;
                        else if (isForeignCurrency) {
                            const isRegionalCurrencyNotEditable = regionalCurrencyNotEditable.includes(params.field);
                            return !(isRegionalCurrencyNotEditable && isForeignCurrency);
                        }
                        return !!params.colDef.editable;
                    }}
                />
            </ProvidePricesInvalidator>
            <Dialog onClose={() => setErrorModalMessage('')} open={!!errorModalMessage}>
                <DialogTitle> Edit not allowed! </DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>{errorModalMessage}</Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorModalMessage('')}> OK </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

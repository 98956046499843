import { useService } from '@insight2profit/drive-app';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { SaveButton } from 'shared/components/forms';
import { customerPriceStatuses, QUERY_KEYS } from 'shared/constants';
import { useUserPermissions } from 'shared/hooks';
import { useCustomerPricesExceptionsSubmitMutation } from 'shared/mutations';
import { useCustomerPricesExceptionsSubmitEmailMutation } from 'shared/mutations/customerPricesExceptionsSubmitEmailMutation';
import { ISpGetCustomerPricesExceptions, IViewCustomerPrices } from 'shared/types';

export function CustomerPricesExceptionsSubmitAction({
    params,
    customerPricesRow,
}: {
    params: GridRowParams<ISpGetCustomerPricesExceptions>;
    customerPricesRow?: IViewCustomerPrices;
}) {
    const { customerPricesCommentsService } = useService();
    const { onSubmit, isLoading } = useCustomerPricesExceptionsSubmitMutation({ customerPricesRow });
    const { isLoading: isEmailMutationLoading } = useCustomerPricesExceptionsSubmitEmailMutation();
    const permissions = useUserPermissions();
    const mostRecentCommentQuery = useQuery({
        queryKey: [
            QUERY_KEYS.customerPriceComments,
            customerPricesRow?.materialId,
            customerPricesRow?.orgRegion,
            customerPricesRow?.shipToId,
            customerPricesRow?.soldToId,
            'top 1',
        ],
        queryFn: async () =>
            await customerPricesCommentsService.getComments({
                pageNumber: 0,
                pageSize: 1,
                sortModel: [{ field: 'effectiveStart', sort: 'desc' }],
                filterModel: {
                    items: [
                        {
                            columnField: 'materialId',
                            value: customerPricesRow?.materialId,
                            operatorValue: 'equals',
                        },
                        { columnField: 'orgRegion', value: customerPricesRow?.orgRegion, operatorValue: 'equals' },
                        { columnField: 'shipToId', value: customerPricesRow?.shipToId, operatorValue: 'equals' },
                        { columnField: 'soldToId', value: customerPricesRow?.soldToId, operatorValue: 'equals' },
                    ],
                },
            }),
        enabled: Boolean(
            customerPricesRow?.materialId &&
                customerPricesRow?.orgRegion &&
                customerPricesRow?.shipToId &&
                customerPricesRow?.soldToId
        ),
    });

    if (params.row.status === 'Approved') return <Box>Submitted</Box>;

    const isSubmitAllowed =
        params.row.status === customerPriceStatuses.NEEDS_REVIEW ||
        params.row.status === customerPriceStatuses.NO_CHANGE ||
        params.row.status === customerPriceStatuses.APPROVED;

    return (
        <SaveButton
            submitHandler={() => {
                const mostRecentComment = mostRecentCommentQuery.data?.data[0]?.comment;
                onSubmit(params.row, mostRecentComment);
            }}
            isLoading={isLoading || isEmailMutationLoading || mostRecentCommentQuery.isLoading}
            isDisabled={!isSubmitAllowed || !permissions.data.isCustomerExceptionsWriteAllowed}
            text='Submit'
        />
    );
}

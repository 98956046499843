import {
    GridPreProcessEditCellProps,
    GridValidRowModel,
    GridValueFormatterParams,
    GridValueGetterParams,
    GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { isNil } from 'lodash';
import { IExchangeRates, currencyCodeMapper } from 'shared/providers';
import { IUomConversionService } from 'shared/services';
import {
    ICustomerPricesMassActionValidation,
    ISpGetCustomerPriceHistory,
    ISpGetCustomerPricesExceptions,
    IViewCustomerPrices,
    IViewMarketPriceHistory,
    IViewProductPrices,
} from 'shared/types';
import { cmPercent } from './contributionMargin';
import { formatDate, formatLongDateString, formatMoney, formatPercent } from './formats';
import { distinctObjectArray } from './validation';

// Value Formatter
export const moneyValueFormatter: (params: GridValueFormatterParams<any>) => any = ({ value }) => {
    return value || value === 0 ? formatMoney(value) : '';
};

export const percentValueFormatter: (params: GridValueFormatterParams<any>) => any = ({ value }) => {
    return value || value === 0 ? formatPercent(value) : '';
};

export const percentValueFormat = (value: number | undefined) => {
    return value || value === 0 ? formatPercent(value) : '';
};

export const shortDateValueFormatter = (params: GridValueFormatterParams<string>) =>
    isNil(params.value) ? '' : formatDate(new Date(params.value));

export const longDateValueFormatter = (params: GridValueFormatterParams<string>) =>
    isNil(params.value) ? '' : formatLongDateString(params.value);

export const modifiedByFormatter = (params: GridValueFormatterParams<string>) => {
    if (!params.value) return '';
    if (params.value.indexOf('|MA|') === -1) return params.value;
    return params.value?.substring(0, params.value.indexOf('|MA|'));
};

// Validation
export const PositiveNonZero = (params: GridPreProcessEditCellProps) => {
    if (isNil(params?.props?.value)) {
        return { ...params.props, error: 'Price cannot be blank' };
    }
    const hasError = params.props.value <= 0;
    if (hasError) return { ...params.props, error: 'Price must be greater than 0' };

    return { ...params.props, error: false };
};

export const customerPricesForeignCurrencyValueGetterCurried = ({
    isForeignCurrency,
    exchangeRates,
}: {
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
}) => (
    params: GridValueGetterParams<
        number,
        | IViewCustomerPrices
        | ISpGetCustomerPriceHistory
        | ISpGetCustomerPricesExceptions
        | ICustomerPricesMassActionValidation
    >
) => {
    if (!isForeignCurrency) return params.value;

    const currencyCode = params.row.documentCurrency;
    const matchingExchangeRate = exchangeRates?.data?.find(
        anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode
    )?.exchangeRate;

    const finalValue = isNil(params.value) ? undefined : params.value * (matchingExchangeRate ?? 1);
    return finalValue;
};

export const productPricesAdjustMarginValueGetterCurried = <T extends GridValidRowModel>({
    priceColumnName,
}: {
    priceColumnName: keyof T;
}) => (params: GridValueGetterParams<number, IViewProductPrices>) => {
    return cmPercent({
        cost: params.row.updatedCost,
        price: params.row[priceColumnName as keyof typeof params.row] as number,
    });
};

export const productPricesForeignCurrencyValueGetterCurried = ({
    isForeignCurrency,
    exchangeRates,
}: {
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
}) => (params: GridValueGetterParams<number, IViewProductPrices | IViewMarketPriceHistory>): number | undefined => {
    return convertProductPricesToForeignCurrencyValue({
        usdPrice: params.value,
        exchangeRates,
        orgRegion: params.row.orgRegion,
        isForeignCurrency,
    });
};

export const convertProductPricesToForeignCurrencyValue = ({
    usdPrice,
    exchangeRates,
    orgRegion,
    isForeignCurrency,
}: {
    usdPrice?: number;
    exchangeRates: IExchangeRates;
    orgRegion: string;
    isForeignCurrency: boolean;
}) => {
    if (!isForeignCurrency) return usdPrice;
    if (!usdPrice) return usdPrice;
    const currencyCode = currencyCodeMapper(orgRegion);
    const matchingExchangeRate =
        exchangeRates?.data?.find(anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode)?.exchangeRate ??
        1;

    const formatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'code',
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 21,
    });

    if (!usdPrice) return usdPrice;

    const foreignPrice = usdPrice * (matchingExchangeRate ?? 1);

    const formattedForeignPrice = formatter.format(foreignPrice);

    const foreignPriceString = formattedForeignPrice.replace(currencyCode, '').replaceAll(',', '').trim();

    const foreignPriceFinal = Number(foreignPriceString);

    return foreignPriceFinal;
};

export const customerPricesForeignCurrencyValueSetterCurried = <
    T extends GridValidRowModel & { documentCurrency?: string }
>({
    isForeignCurrency,
    exchangeRates,
    priceColumnName,
}: {
    priceColumnName: keyof T;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
}) => (params: GridValueSetterParams<T, any>) => {
    if (!isForeignCurrency) return { ...params.row, [priceColumnName]: params.value };
    const currencyCode = params.row?.documentCurrency;

    const matchingExchangeRate = exchangeRates?.data?.find(
        anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode
    )?.exchangeRate;

    return { ...params.row, [priceColumnName]: params.value / (matchingExchangeRate ?? 1) };
};

export const productPricesForeignCurrencyAndUomValueSetterCurried = ({
    isForeignCurrency,
    exchangeRates,
    priceColumnName,
}: {
    priceColumnName: keyof IViewProductPrices;
    isForeignCurrency: boolean;
    exchangeRates: IExchangeRates;
}) => (params: GridValueSetterParams<IViewProductPrices, number>) => {
    const editedValue = params.value;

    const isInUsd = !isForeignCurrency;

    const currencyCode = currencyCodeMapper(params.row.orgRegion);
    const matchingExchangeRate = exchangeRates?.data?.find(
        anExchangeRate => anExchangeRate.fromCurrencyCode === currencyCode
    )?.exchangeRate;

    const usdEditedPrice = isInUsd ? editedValue : editedValue / (matchingExchangeRate ?? 1);

    const isDirty = usdEditedPrice !== editedValue;

    if (isDirty) {
        return {
            ...params.row,
            [priceColumnName]: usdEditedPrice,
        };
    }

    return {
        ...params.row,
        [priceColumnName]: editedValue,
    };
};

export const productPricesUomValueGetterCurried = ({
    uomConversionService,
}: {
    uomConversionService: IUomConversionService;
}) => (params: GridValueGetterParams<number, IViewProductPrices>) => {
    if (params.row.uom === 'KAI' || !params.value) {
        return params.value;
    }

    if (params.row.nonStandardUomConversionFactor) {
        return uomConversionService.convertKaiToNonStandardUom({
            kaiPrice: params.value,
            kaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
            toNonStandardConversionFactor: params.row.nonStandardUomConversionFactor,
        });
    }

    return uomConversionService.convertKaiToUom({
        kaiPrice: params.value,
        materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
        kgConversion: params.row.kgConversion,
    });
};

export const productPricesQuantityUomValueGetterCurried = ({
    uomConversionService,
}: {
    uomConversionService: IUomConversionService;
}) => (params: GridValueGetterParams<number, IViewProductPrices>) => {
    if (params.row.uom === 'KAI' || !params.value) {
        return params.value;
    }

    if (params.row.nonStandardUomConversionFactor) {
        return uomConversionService.convertQuantityKaiToNonStandardUom({
            kaiQuantity: params.value,
            kaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
            toNonStandardConversionFactor: params.row.nonStandardUomConversionFactor,
        });
    }

    return uomConversionService.convertQuantityKaiToUom({
        kaiQuantity: params.value,
        materialKaiKgConversionFactor: params.row.materialKaiKgConversionFactor,
        kgConversion: params.row.kgConversion,
    });
};

export const PositiveNotBlank = (params: GridPreProcessEditCellProps) => {
    if (isNil(params?.props?.value)) {
        return { ...params.props, error: true };
    }
    const hasError = params.props.value === '' || params.props.value < 0;
    return { ...params.props, error: hasError };
};

export const ValidFromPreProcessor = ({ props, row }: GridPreProcessEditCellProps) => {
    if (isNil(props.value)) {
        return { ...props, error: true };
    }
    const isAfterValidTo = props.value > new Date(row.validTo);
    return { ...props, error: isAfterValidTo };
};

export const ValidToPreProcessor = ({ props, row }: GridPreProcessEditCellProps) => {
    if (isNil(props.value)) {
        return { ...props, error: true };
    }
    const isInThePast = props.value < new Date();
    const isBeforeValidFrom = props.value < new Date(row.validFrom);
    return { ...props, error: isInThePast || isBeforeValidFrom };
};

export const ValidFromPreProcessorWithMessage = ({ props, row }: GridPreProcessEditCellProps) => {
    if (isNil(props.value)) {
        return { ...props, error: true };
    }
    const isAfterValidTo = props.value > new Date(row.validTo);
    if (isAfterValidTo) {
        return { ...props, error: 'Date cannot be after Valid To date' };
    }
    return { ...props, error: false };
};

export const ValidToPreProcessorWithMessage = ({ props, row }: GridPreProcessEditCellProps) => {
    if (isNil(props.value)) {
        return { ...props, error: true };
    }
    const isInThePast = props.value < new Date();
    const isBeforeValidFrom = props.value < new Date(row.validFrom);
    if (isInThePast) {
        return { ...props, error: 'Date cannot be in the past' };
    }
    if (isBeforeValidFrom) {
        return { ...props, error: 'Date cannot be before valid from date' };
    }

    return { ...props, error: false };
};

export const compareDescendingAmountValues = ({
    params,
    previousAmountFieldName,
}: {
    params: GridPreProcessEditCellProps<any, IViewCustomerPrices>;
    previousAmountFieldName: keyof IViewCustomerPrices;
}) => {
    const currentEditedValue = params.props?.value;
    const previousAmountValue = params?.otherFieldsProps?.[previousAmountFieldName].value;

    if (currentEditedValue && (previousAmountValue || previousAmountValue === 0)) {
        if (currentEditedValue >= previousAmountValue)
            return { ...params.props, error: 'Amount values must be descending, check your previous amount' };
    }

    return {
        ...params.props,
        error: false,
    };
};

export const compareAscendingScaleValues = (params: GridPreProcessEditCellProps, previousScaleFieldName: string) => {
    let error = false;
    if (params.props.value && params?.otherFieldsProps && params.otherFieldsProps[previousScaleFieldName]) {
        if (params.props.value && params.otherFieldsProps[previousScaleFieldName].value) {
            //scale 5 must be greater than scale 4
            error = !(Number(params.props.value) >= Number(params.otherFieldsProps[previousScaleFieldName].value));
        }
    }

    return {
        ...params.props,
        error: error ? 'Scale values must be ascending, check your previous scale' : false,
    };
};

export const perQuantityValidationCheck = (params: GridPreProcessEditCellProps) => {
    const error = params.props.value <= 0;
    return {
        ...params.props,
        error: error ? 'Value must be greater than 0' : false,
    };
};

export function determineIsEdited<T extends {}>(oldRowEditableValues: Partial<T>, newRowEditableValues: Partial<T>) {
    const rowsToCompare = [oldRowEditableValues, newRowEditableValues];

    return distinctObjectArray(rowsToCompare).length === 2;
}

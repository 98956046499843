import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface IAlertModalProps {
    onClose: () => void;
    open: boolean;
    title: string;
    message: string;
}

export function AlertModal({ onClose, open, title, message }: IAlertModalProps) {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle> {title} </DialogTitle>
            <DialogContent>
                <Alert severity='warning'>{message}</Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}> OK </Button>
            </DialogActions>
        </Dialog>
    );
}
